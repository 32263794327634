body {
    margin: 0;
}

#page-subtitle {
    font-size: 25px;
    margin-bottom: 0;
}

#header {
    text-align: center;
    background-color: white;
}

#navigation {
    padding: 15px 15px;
}

#navigation a {
    text-align: center;
    font-size: 20px;
    padding: 0px 20px;
}

@media (min-width: 768px) {
    #navigation div:not(:last-child) {
        border-right: 1px solid black;
    }
}

.backTop {
    position: fixed;
    bottom: 2px;
    right: 2px;
    padding: 10px;
    color: #b0751c;
    background-color: white;
    border: 1px solid #b0751c;
    cursor: pointer;
}

.backTop:hover {
    background-color: #ffc369;
}

#siteTitle > a {
    cursor: pointer;
    color: #b0751c;
}