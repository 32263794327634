
.tags {
    list-style-type: none;
    padding: 0;
    margin-top: 10px;
    max-width: 100%;
}

.tags-filter-container {
    text-align: center;
}

.tags-filter-container p {
    margin-bottom: 0;
}

.tags-filter {
    margin: auto;
    width: max-content;
    display: block;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    padding: 2px 0px;
}



.tag {
    margin: 5px;
    display: inline-block;
    padding: 5px;
    border: 1px solid black;
    user-select: none;
    border-radius: 10px;
    background-color: #fffaed;
}

.selected {
    background-color: #ffe39c;
}