
.project-summary-div img {
    padding: 5px;
    border-top: 2px solid #b0751c;
    border-bottom: 2px solid #b0751c;
    margin-bottom: 15px;
    height: 300px;
    max-width: 100%;
}

.project-summary-footer-div {
    text-align: right;
}
q
.project-summary-body-div p {
    margin-top: 10px;
    min-height: 70px;
}

.starred {
    background-color: #b0751c;
    vertical-align: super;
    font-size: 16px;
    padding: 5px;
    color: white;
}