
#news p {
    display: inline;
}

.center-box {
    max-width: 800px;
    padding-top: 10px;
    margin: auto;
    margin-bottom: 20px;
}

.box-border-top {
    border-top: 1px solid black;
}

#news-header {
    margin-top: 50px;
    border-bottom: 1px solid black;
    margin-bottom: 10px;
}